export default interface NonReconciliationMotive {
  id: number;
  name: string;
  readableName: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

const firedEmployee: NonReconciliationMotive = {} as NonReconciliationMotive;
firedEmployee.id = 1;
firedEmployee.name = "FUNCIONARIO_DESLIGADO";
firedEmployee.readableName = "Func. desligado";

const employeeAway: NonReconciliationMotive = {} as NonReconciliationMotive;
employeeAway.id = 2;
employeeAway.name = "FUNCIONARIO_AFASTADO";
employeeAway.readableName = "Func. afastado";

const employeeWithoutMarginBase: NonReconciliationMotive =
  {} as NonReconciliationMotive;
employeeWithoutMarginBase.id = 3;
employeeWithoutMarginBase.name = "FUNCIONARIO_SEM_MARGEM";
employeeWithoutMarginBase.readableName = "Func. sem margem";

const generatedAfterConciliation: NonReconciliationMotive =
  {} as NonReconciliationMotive;
generatedAfterConciliation.id = 4;
generatedAfterConciliation.name = "GERADO_APOS_CONCILIACAO";
generatedAfterConciliation.readableName = "Gerado após a conciliação";

export const NonReconciliationMotiveEnum = {
  FIRED_EMPLOYEE: firedEmployee,
  EMPLOYEE_AWAY: employeeAway,
  EMPLOYEE_WITHOUT_MARGIN_BASE: employeeWithoutMarginBase,
  GENERATED_AFTER_CONCILIATION: generatedAfterConciliation
};

export const getNonReconciliationMotiveByName = (
  name: string
): NonReconciliationMotive | undefined => {
  return Object.values(NonReconciliationMotiveEnum).find(
    (motive) => motive.name === name
  );
};
