
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Decimal from "decimal.js";
import Batch from "@/types/batch";
import Modal from "@/components/Modal.vue";
import SaveButton from "@/components/SaveButton.vue";
import { mask } from "vue-the-mask";
import LoanService from "@/services/loan-service";
import { ValidationProvider } from "vee-validate";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { format as formatCNPJ } from "@/utils/cnpj";
import { BatchStatusEnum } from "@/types/batch-status";
import BatchUpdateStatusData from "@/services/loan-service/types/batch-update-status-data";
import { ExtendedBatchRegister } from "@/types/batch-register";
import DatePicker from "@/components/DatePicker.vue";
import dayjs from "dayjs";

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Modal,
    SaveButton,
    DatePicker
  },
  directives: { mask }
})
export default class ReopenBatchModalModal extends Vue {
  form: BatchUpdateStatusData;
  formatCNPJ = formatCNPJ;
  BatchStatusEnum = BatchStatusEnum;
  service: LoanService;
  loading = true;
  batchRegisters: ExtendedBatchRegister[] = [];
  maxDueDate: string = "";
  minDueDate: string = "";
  limitDate: string = "";

  @Prop() readonly batch!: Batch;
  constructor() {
    super();
    this.service = LoanService.getInstance();
    this.form = {
      status: BatchStatusEnum.OPEN.name,
      observation: "",
      dueDate: ""
    };
  }

  async created(): Promise<void> {
    if (this.batch) {
      await this.fetchBatchRegisters();
      this.form.dueDate = this.batch.dueDate;
      this.limitDate = this.batch.limitDate;
      this.maxDueDate = dayjs().add(15, "days").format("YYYY-MM-DD");
      this.minDueDate = dayjs().add(3, "days").format("YYYY-MM-DD");
    }
  }

  async save(): Promise<void> {
    this.loading = true;
    const [error, updatedBatch] = await this.service.updateBatchStatus(
      this.batch.id,
      this.form
    );

    this.loading = false;
    if (error) {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    } else {
      this.$emit("input");
    }
  }

  async fetchBatchRegisters(): Promise<ExtendedBatchRegister[]> {
    this.loading = true;
    const [error, batchRegisters] = await this.service.listBatchRegisters({
      batchId: this.batch.id
    });

    if (!error) {
      this.batchRegisters = batchRegisters!;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }

    this.loading = false;
    return this.batchRegisters;
  }

  handleDueDateChange() {
    this.limitDate = dayjs(this.form.dueDate)
      .subtract(3, "days")
      .format("YYYY-MM-DD");
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }

  get formTitle(): string {
    return "Reabrir lote " + this.batch.id;
  }

  get installmentsTotal(): number {
    let total = new Decimal(0);
    this.batchRegisters.forEach((batchRegister: ExtendedBatchRegister) => {
      total = total.plus(new Decimal(batchRegister.loanInstallment.value));
    });
    return total.toNumber();
  }

  get total(): number {
    let total = new Decimal(0);
    this.batchRegisters.forEach((batchRegister: ExtendedBatchRegister) => {
      if (batchRegister.reconciliate) {
        if (batchRegister.reconciliationValue) {
          total = total.plus(new Decimal(batchRegister.reconciliationValue));
        }
      } else if (batchRegister.severancePayValue) {
        total = total.plus(new Decimal(batchRegister.severancePayValue));
      }
    });
    return total.toNumber();
  }

  get isSaveDisabled(): boolean {
    const today = dayjs().endOf("date");
    const minDueDate = today.add(3, "days");
    const dueDate = dayjs(this.form.dueDate);
    return dueDate.isBefore(minDueDate);
  }
}
