
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import CompanyService, {
  ListCompaniesResponse
} from "@/services/company-service";
import LoanService from "@/services/loan-service";
import BatchesList from "@/services/loan-service/types/list-batches-response";
import BatchRegisterPortabilityData from "../../services/loan-service/types/batch-register-portability-data";
import { BatchStatusEnum } from "@/types/batch-status";
import { format as formatCNPJ } from "@/utils/cnpj";
import dayjs from "dayjs";

@Component({
  components: { ValidationObserver }
})
export default class BatchRegisterCompanyUpdateModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: BatchRegisterPortabilityData;
  isLoading: boolean = false;
  notFoundOpenBatchDialog: boolean = false;
  selectedCompany: number | null = null;
  selectedBatch: number | null = null;
  companies: ListCompaniesResponse = {
    items: [],
    total: 0
  };
  batches: BatchesList = {
    items: [],
    total: 0
  };
  companyService: CompanyService;
  loanService: LoanService;
  formatCNPJ = formatCNPJ;
  BatchStatusEnum = BatchStatusEnum;

  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
    this.loanService = LoanService.getInstance();
  }

  async created() {
    this.isLoading = true;
    const [getCompanyError, getCompanyData] =
      await this.companyService.getCompany(this.item.companyId);

    if (getCompanyError) {
      this.$notify({
        title: "Erro ao obter dados de empresa",
        type: "error",
        text: "Empresas não encontradas."
      });
    }

    if (getCompanyData) {
      const [error, companies] = await this.companyService.listCompanies({
        page: 1,
        limit: -1,
        sort: "name:ASC",
        groupId: getCompanyData.groupId
      });
      companies.items = companies.items.filter(
        (item) => item.id !== getCompanyData.id
      );
      this.companies = companies;
      this.isLoading = false;
    }
  }

  handleBatchRegisterUpdateOwner(): void {
    this.$emit("input", {
      companyId: this.selectedCompany,
      destinationBatchId: this.selectedBatch,
      batchRegisterId: this.item.batchRegister.id
    });

    this.isLoading = true;
    this.selectedCompany = null;
    this.selectedBatch = null;
  }

  async validateSelection(companyId): Promise<void> {
    this.resetModal();
    this.isLoading = true;
    const [openBatchesError, openBatches] = await this.loanService.listBatches({
      companyId: Number(companyId),
      status: BatchStatusEnum.OPEN.name,
      limit: -1,
      page: 1
    });

    const [treatmentBatchesError, treatmentBatches] =
      await this.loanService.listBatches({
        companyId: Number(companyId),
        status: BatchStatusEnum.EDITING.name,
        limit: -1,
        page: 1
      });

    if (openBatchesError || treatmentBatchesError) {
      this.$notify({
        title: "Erro ao buscar lotes",
        type: "error",
        text: "Não foi possível buscar lotes abertos ou em tratamento."
      });
      this.isLoading = false;
      return;
    }

    const combinedBatches = [...openBatches.items, ...treatmentBatches.items];

    if (combinedBatches.length === 0) {
      this.$notify({
        title: "Nenhum lote encontrado",
        type: "error",
        text: "A empresa selecionada não possui lotes abertos ou em tratamento."
      });

      this.isLoading = false;
      return;
    }

    this.batches = {
      items: combinedBatches,
      total: openBatches.total + treatmentBatches.total
    };

    this.isLoading = false;
  }

  get portabilityDate(): string {
    return this.formatDate(new Date());
  }

  formatDate(date?: Date | null | undefined): string {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
  }

  formatReferenceDate(date?: Date | null | undefined): string {
    return date ? dayjs(date).format("MM/YYYY") : "";
  }

  resetModal(): void {
    this.selectedBatch = null;
    this.batches = {
      items: [],
      total: 0
    };
  }
}
