
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Decimal from "decimal.js";
import dayjs from "@/plugins/day-js";
import Batch from "@/types/batch";
import Modal from "@/components/Modal.vue";
import { mask } from "vue-the-mask";
import LoanService from "@/services/loan-service";
import PaymentService from "@/services/payment-service";
import CompanyService from "@/services/company-service";
import { ValidationProvider } from "vee-validate";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import ListBatchRegistersParams from "@/services/loan-service/types/list-batch-registers-params";
import Payments from "@/services/payment-service/types/payments";
import Company from "@/types/company";
import { DataOptions, DataTableHeader } from "vuetify";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import { BatchStatusEnum } from "@/types/batch-status";
import { PaymentStatusEnum } from "@/types/payment-status";
import { PaymentTypeEnum } from "@/types/payment-type";
import { getBankSlipCurrentStatus } from "@/utils/bankslip";
import { ExtendedBatchRegister } from "@/types/batch-register";
import { SegmentsEnum } from "@/data/segmentos";
import BatchesManageImportFileModal, {
  ProcessedData
} from "./BatchesManageImportFileModal.vue";
import { removeNonDigits } from "../../utils/removeNonDigits";
import NonReconciliationMotive, {
  getNonReconciliationMotiveByName,
  NonReconciliationMotiveEnum
} from "../../types/non-reconciliation-motive";

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Modal,
    BatchesManageImportFileModal
  },
  directives: { mask }
})
export default class BatchesManageModal extends Vue {
  formatCNPJ = formatCNPJ;
  formatCPF = formatCPF;
  BatchStatusEnum = BatchStatusEnum;
  PaymentTypeEnum = PaymentTypeEnum;
  PaymentStatusEnum = PaymentStatusEnum;
  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL;
  service: LoanService;
  paymentService: PaymentService;
  companyService: CompanyService;
  loading = true;
  loadingXLS = false;
  changed = false;
  showImportModal = false;
  batchRegisters: ExtendedBatchRegister[] = [];
  batchRegistersAfterConciliation: ExtendedBatchRegister[] = [];
  payments: Payments;
  loadingPayments: boolean;
  paymentType: string;
  paymentLabel: string;
  paymentStatus: string;
  paymentAlert: string;
  company: Company;
  segments = SegmentsEnum;
  billingMode = "Boleto";
  nonReconciliationMotives: NonReconciliationMotive[] = [
    NonReconciliationMotiveEnum.FIRED_EMPLOYEE,
    NonReconciliationMotiveEnum.EMPLOYEE_AWAY,
    NonReconciliationMotiveEnum.EMPLOYEE_WITHOUT_MARGIN_BASE,
    NonReconciliationMotiveEnum.GENERATED_AFTER_CONCILIATION,
    ...(this.hasPermissions(["TRANSFERIR_CONTRATO"])
      ? [
          {
            name: "TRANSFERIR_CONTRATO"
          } as NonReconciliationMotive
        ]
      : [])
  ];
  nonReconciliationMotivesDescriptions: { [name: string]: string } = {
    [NonReconciliationMotiveEnum.FIRED_EMPLOYEE.name]: "Funcionário desligado",
    [NonReconciliationMotiveEnum.EMPLOYEE_AWAY.name]: "Funcionário afastado",
    [NonReconciliationMotiveEnum.EMPLOYEE_WITHOUT_MARGIN_BASE.name]:
      "Funcionário sem margem",
    [NonReconciliationMotiveEnum.GENERATED_AFTER_CONCILIATION.name]:
      "Gerado após conciliação",
    TRANSFERIR_CONTRATO: "Transferir contrato"
  };
  filters = {
    borrowerCpf: "",
    borrowerName: "",
    loanId: "",
    reconciliate: null,
    nonReconciliationMotive: ""
  };
  filteredBatchRegisters: ExtendedBatchRegister[] = [];
  paginationOptions: DataOptions = {} as DataOptions;
  headers: Array<DataTableHeader> = [
    {
      text: "Colaborador",
      value: "loanInstallment.loan.borrowerName"
    },
    { text: "Proposta", value: "loanInstallment.loan.id" },
    { text: "Parcela", value: "loanInstallment.number" },
    { text: "Prazo", value: "loanInstallment.loan.numInstallments" },
    { text: "Valor parcela", value: "loanInstallment.value" },
    { text: "Saldo devedor", value: "loanInstallment.debitBalanceValue" },
    { text: "Consigna", value: "reconciliate" },
    { text: "Valor repasse", value: "reconciliationValue" },
    { text: "Motivo", value: "nonReconciliationMotive" },
    { text: "Verbas rescisórias", value: "severancePayValue" }
  ];
  getBankSlipCurrentStatus = getBankSlipCurrentStatus;

  @Prop() readonly batch!: Batch;
  @Prop() readonly editable!: Batch;
  @Prop() readonly showPayment!: Batch;

  constructor() {
    super();
    this.service = LoanService.getInstance();
    this.paymentService = PaymentService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.payments = { bankSlips: [] };
    this.loadingPayments =
      this.batch.status.name === BatchStatusEnum.CLOSED.name;
  }

  async created() {
    if (this.batch) {
      await this.fetchBatchRegisters();
      this.filteredBatchRegisters = [...this.batchRegisters];

      const [getCompanyError, getCompanyData] =
        await this.companyService.getCompany(this.batch.companyId);
      if (getCompanyError) {
        const message = getErrorMessageFromApiError(getCompanyError);
        this.$notify({
          title: "Erro ao obter dados de empresa",
          type: "error",
          text: message
        });
      }

      if (getCompanyData) {
        this.company = getCompanyData;
      }

      if (this.company.segmentId == this.segments.Publico.id) {
        this.billingMode = "Cobrança";
      }

      if (this.batch.status.name === BatchStatusEnum.CLOSED.name) {
        this.fetchPayments();
      }
    }
  }

  async fetchPayments(): Promise<Payments> {
    this.loadingPayments = true;
    const [error, payments] = await this.paymentService.listPaymentsByBatchIds([
      this.batch.id
    ]);
    this.loadingPayments = false;

    if (!error) {
      this.payments = payments!;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }

    if (
      this.payments &&
      this.payments?.paymentOthers &&
      this.payments.paymentOthers.length
    ) {
      this.paymentType = "paymentOthers";
      this.paymentLabel = "Situação do pagamento";
      this.paymentStatus = "Status da cobrança";
      this.paymentAlert = "O cobrança já foi paga";
    }
    if (
      this.payments &&
      this.payments?.bankSlips &&
      this.payments.bankSlips.length
    ) {
      this.paymentType = "bankSlips";
      this.paymentLabel = "Situação do boleto";
      this.paymentStatus = "Status do boleto";
      this.paymentAlert = "O boleto já foi pago!";
    }

    return this.payments;
  }

  async fetchBatchRegisters(): Promise<ExtendedBatchRegister[]> {
    this.loading = true;
    const [error, batchRegisters] = await this.service.listBatchRegisters({
      batchId: this.batch.id
    });

    if (!error) {
      this.batchRegisters = batchRegisters!
        .filter(
          (batchRegister) =>
            batchRegister.generatedAfterConciliation === null ||
            batchRegister.generatedAfterConciliation === false
        )
        .map(this.fixBatchRegisterData);
      this.batchRegistersAfterConciliation = batchRegisters!.filter(
        (batchRegister) => batchRegister.generatedAfterConciliation === true
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }

    this.loading = false;
    return this.batchRegisters;
  }

  filterBatchRegisters(): void {
    const parsedFilters = {
      ...this.filters,
      borrowerCpf: this.filters.borrowerCpf
        ? removeNonDigits(this.filters.borrowerCpf)
        : "",
      borrowerName: this.filters.borrowerName
        ? this.filters.borrowerName.trim().toLowerCase()
        : "",
      loanId: this.filters.loanId
        ? removeNonDigits(this.filters.loanId.toString())
        : ""
    };

    this.filteredBatchRegisters = this.batchRegisters.filter((register) => {
      return (
        (!parsedFilters.borrowerCpf ||
          register.loanInstallment.loan.borrowerCpf ===
            removeNonDigits(parsedFilters.borrowerCpf)) &&
        (!parsedFilters.borrowerName ||
          register.loanInstallment.loan.borrowerName
            .toLowerCase()
            .includes(parsedFilters.borrowerName.toLowerCase())) &&
        (!parsedFilters.loanId ||
          register.loanInstallment.loan.id.toString() ===
            parsedFilters.loanId) &&
        (parsedFilters.reconciliate === null ||
          register.reconciliate === parsedFilters.reconciliate) &&
        (!parsedFilters.nonReconciliationMotive ||
          register.nonReconciliationMotive.name ===
            parsedFilters.nonReconciliationMotive)
      );
    });
  }

  openImportModal(): void {
    this.showImportModal = true;
  }

  closeImportModal(): void {
    this.showImportModal = false;
  }

  handleImportFile(importedData: ProcessedData): void {
    const batchRegisterLoanIds = new Set(
      this.batchRegisters.map(
        (batchRegister) => batchRegister.loanInstallment.loan.id
      )
    );

    const notFoundLoanIds = importedData
      .filter(
        (importedBatchRegister) =>
          !batchRegisterLoanIds.has(importedBatchRegister.loanId)
      )
      .map((importedBatchRegister) => importedBatchRegister.loanId);

    if (notFoundLoanIds.length > 0) {
      const notFoundMessage = this.$notify({
        type: "error",
        title: "Erro ao importar arquivo",
        text: `As seguintes propostas informadas na planilha não estão presentes no lote ${
          this.batch.id
        }: ${notFoundLoanIds.join(", ")}.`,
        duration: 10000
      });
      return;
    }

    this.batchRegisters = this.batchRegisters.map((batchRegister) => {
      const importedBatchRegister = importedData.find(
        (importedBatchRegister) =>
          importedBatchRegister.loanId === batchRegister.loanInstallment.loan.id
      );
      if (importedBatchRegister) {
        batchRegister.reconciliate = importedBatchRegister.reconciliate;
        batchRegister.reconciliationValue = String(
          importedBatchRegister.reconciliationValue
        );
        batchRegister.nonReconciliationMotive =
          getNonReconciliationMotiveByName(
            importedBatchRegister.nonReconciliationMotive
          );
        batchRegister.severancePayValue = String(
          importedBatchRegister.severancePayValue
        );
      }
      return this.fixBatchRegisterData(batchRegister);
    });

    this.$notify({
      type: "success",
      title: "Arquivo importado com sucesso"
    });

    this.batchRegisterChanged();
  }

  async updateBatchRegisters(): Promise<ExtendedBatchRegister[]> {
    this.loading = true;
    this.batchRegisters = this.batchRegisters.map((batchRegister) => {
      return this.fixBatchRegisterData(batchRegister);
    });

    const [error, batchRegisters] = await this.service.updateBatchRegisters({
      batchId: this.batch.id,
      batchRegisters: this.batchRegisters.map((batchRegister) => ({
        id: batchRegister.id,
        reconciliate: batchRegister.reconciliate,
        reconciliationValue: batchRegister.reconciliationValue,
        nonReconciliationMotive: batchRegister.nonReconciliationMotive?.name,
        severancePayValue: batchRegister.severancePayValue,
        loanId: batchRegister.loanInstallment.loan.id
      }))
    });

    if (!error) {
      this.$notify({ type: "success", text: "Alterações salvas!" });
      this.changed = false;
      this.$emit("input");
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }

    this.loading = false;
    return this.batchRegisters;
  }

  get selectableNonReconciliationMotives(): NonReconciliationMotive[] {
    return this.nonReconciliationMotives.filter(
      (motive) => motive.name !== "GERADO_APOS_CONCILIACAO"
    );
  }

  async downloadXLS(): Promise<void> {
    this.loadingXLS = true;
    const [error, data] = await this.service.generateBatchRegistersXls({
      batchId: this.batch.id
    });
    if (!error) {
      window.open(
        process.env.VUE_APP_LOAN_URL! +
          "/batch-registers/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingXLS = false;
  }

  async closeBatchAndGenerateBankSlip(): Promise<void> {
    this.loading = true;
    const [error] = await this.service.closeBatchAndGenerateBankSlip(
      this.batch.id
    );

    this.loading = false;
    if (!error) {
      this.$notify({ type: "success", text: "Lote fechado!" });
      this.$emit("input");
      this.fetchPayments();
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  async generateBankSlip(): Promise<void> {
    this.loadingPayments = true;
    const [error] = await this.service.generateBankSlip(this.batch.id);
    this.loadingPayments = false;
    if (!error) {
      this.$notify({ type: "success", text: "Boleto gerado!" });
      this.fetchPayments();
    } else {
      this.$notify({
        type: "error",
        text:
          getErrorMessageFromApiError(error) ||
          "Ocorreu um erro ao gerar o boleto!"
      });
    }

    return;
  }

  validateSeverancePayValue(value?: string): boolean {
    if (value) {
      return Number(value) >= 0;
    }
    return false;
  }

  validateReconciliationValue(value?: string): boolean {
    if (value) {
      return Number(value) > 0;
    }
    return false;
  }

  get validateBatchRegisters(): boolean {
    if (this.batchRegisters.length > 0) {
      return this.batchRegisters.every((batchRegister) => {
        if (batchRegister.reconciliate) {
          return this.validateReconciliationValue(
            batchRegister.reconciliationValue
          );
        } else {
          if (batchRegister.nonReconciliationMotive) {
            if (
              batchRegister.nonReconciliationMotive.name ===
              "FUNCIONARIO_DESLIGADO"
            ) {
              return this.validateSeverancePayValue(
                batchRegister.severancePayValue
              );
            } else if (
              batchRegister.nonReconciliationMotive.name ===
              "TRANSFERIR_CONTRATO"
            ) {
              this.portabilityMotive({
                batchId: this.batch.id,
                batchRegister: batchRegister,
                companyId: this.batch.companyId
              });
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        }
      });
    }
    return true;
  }

  batchRegisterChanged(): void {
    this.changed = true;
  }

  reconciliateChanged(batchRegister: ExtendedBatchRegister): void {
    batchRegister.reconciliationValue = batchRegister.loanInstallment.value;
  }

  fixBatchRegisterData(
    batchRegister: ExtendedBatchRegister
  ): ExtendedBatchRegister {
    if (batchRegister.reconciliate) {
      batchRegister.nonReconciliationMotive = undefined;
      batchRegister.severancePayValue = "0";
    } else {
      batchRegister.reconciliationValue = undefined;
      if (batchRegister.nonReconciliationMotive) {
        if (
          batchRegister.nonReconciliationMotive.name !== "FUNCIONARIO_DESLIGADO"
        ) {
          batchRegister.severancePayValue = "0";
        }
      } else {
        batchRegister.severancePayValue = "0";
      }
    }
    return batchRegister;
  }

  close(): void {
    this.$emit("close");
  }

  portabilityMotive(batchRegister): void {
    this.$emit("updateBatchRegisterCompany", batchRegister);
  }

  formatDate(date: string): string {
    return dayjs(date).format("DD/MM/YYYY");
  }

  formatNonReconciliationMotive(name?: string): string {
    if (name) {
      const motive = this.nonReconciliationMotives.find((m) => m.name === name);
      if (motive) {
        return this.nonReconciliationMotivesDescriptions[motive.name] || name;
      }
    }
    return "-";
  }

  get formTitle(): string {
    return "Detalhes do lote " + this.batch.id;
  }

  get canEdit(): boolean {
    return (
      this.editable && this.batch.status.name !== BatchStatusEnum.CLOSED.name
    );
  }

  get installmentsTotal(): number {
    let total = new Decimal(0);
    this.batchRegisters.forEach((batchRegister: ExtendedBatchRegister) => {
      total = total.plus(new Decimal(batchRegister.loanInstallment.value));
    });
    if (this.batchRegistersAfterConciliation.length > 0 && this.isAdminGooroo) {
      this.batchRegistersAfterConciliation.forEach(
        (batchRegister: ExtendedBatchRegister) => {
          total = total.plus(new Decimal(batchRegister.loanInstallment.value));
        }
      );
    }
    return total.toNumber();
  }

  get total(): number {
    let total = new Decimal(0);
    this.batchRegisters.forEach((batchRegister: ExtendedBatchRegister) => {
      if (batchRegister.reconciliate) {
        if (batchRegister.reconciliationValue) {
          total = total.plus(new Decimal(batchRegister.reconciliationValue));
        }
      } else if (batchRegister.severancePayValue) {
        total = total.plus(new Decimal(batchRegister.severancePayValue));
      }
    });
    return total.toNumber();
  }

  get downloadURL(): string {
    return (
      process.env.VUE_APP_LOAN_URL +
      "/batch-registers/" +
      this.batch.id +
      "/download"
    );
  }

  get isAdminGooroo(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }
}
