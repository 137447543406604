
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Modal from "@/components/Modal.vue";
import { mask } from "vue-the-mask";
import { ValidationProvider } from "vee-validate";
import DatePicker from "@/components/DatePicker.vue";
import PaymentBankSlip from "@/types/payment-bank-slip";
import PaymentStatus from "@/types/payment-status";
import dayjs from "@/plugins/day-js";
import Decimal from "decimal.js";
import LoanService from "@/services/loan-service";
import { ExtendedBatchRegister } from "@/types/batch-register";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { PaymentStatusEnum } from "@/types/payment-status";
import PaymentService from "@/services/payment-service";

interface Form {
  paymentBankSlipValue: number;
  paymentBankSlipPaidValue: number;
  paymentBankSlipDueDate: string;
  paymentBankSlipPaidDate: string;
  observation: string;
}

@Component({
  components: { ValidationObserver, ValidationProvider, DatePicker, Modal },
  directives: { mask }
})
export default class PaymentBankSlipManageModal extends Vue {
  @Prop() readonly paymentBankSlip!: PaymentBankSlip;
  @Prop() readonly newStatus!: PaymentStatus;

  PaymentStatusEnum = PaymentStatusEnum;
  STATUS_NAMES_THAT_SHOULD_SHOW_PAID_VALUE_AND_DATE = [
    PaymentStatusEnum.PAID.name
  ];
  loanService: LoanService;
  paymentService: PaymentService;
  form: Form = {
    paymentBankSlipValue: 0,
    paymentBankSlipPaidValue: 0,
    paymentBankSlipDueDate: "",
    paymentBankSlipPaidDate: "",
    observation: ""
  };
  isLoadingPaymentBankSlipValue: boolean = false;
  isLoading: boolean = false;
  test = 0;

  constructor() {
    super();
    this.loanService = LoanService.getInstance();
    this.paymentService = PaymentService.getInstance();
  }

  async created(): Promise<void> {
    this.form.paymentBankSlipDueDate = this.formatReadableDate(
      this.paymentBankSlip.dueDate
    );
    this.form.paymentBankSlipPaidDate = this.paymentBankSlip.dueDate;
    this.form.paymentBankSlipValue = await this.getPaymentBankSlipValue();
    this.form.paymentBankSlipPaidValue = this.form.paymentBankSlipValue;
  }

  close(): void {
    this.$emit("close");
  }

  async getPaymentBankSlipValue(): Promise<number> {
    this.isLoadingPaymentBankSlipValue = true;

    let total = new Decimal(0);

    const batchRegisters = await this.fetchBatchRegisters();
    if (batchRegisters) {
      batchRegisters.forEach((batchRegister: ExtendedBatchRegister) => {
        if (batchRegister.reconciliate) {
          if (batchRegister.reconciliationValue) {
            total = total.plus(new Decimal(batchRegister.reconciliationValue));
          }
        } else if (batchRegister.severancePayValue) {
          total = total.plus(new Decimal(batchRegister.severancePayValue));
        }
      });
    }

    this.isLoadingPaymentBankSlipValue = false;
    return total.toNumber();
  }

  async fetchBatchRegisters(): Promise<ExtendedBatchRegister[] | null> {
    this.isLoading = true;

    const filters = {
      batchId: this.paymentBankSlip.payment.paymentBatch.batchId
    };
    const [batchRegistersError, batchRegisters] =
      await this.loanService.listBatchRegisters(filters);

    this.isLoading = false;
    return batchRegisters;
  }

  async save(): Promise<void> {
    if (
      this.shouldShowPaidValueAndDate &&
      (!this.form.paymentBankSlipPaidValue ||
        !this.form.paymentBankSlipPaidDate)
    ) {
      return this.$notify({
        type: "error",
        text: "Preencha todos os dados para prosseguir"
      });
    }

    this.isLoading = true;

    const [error] = await this.paymentService.updatePayment({
      paymentId: this.paymentBankSlip.paymentId,
      status: this.newStatus.name,
      ...(this.form.paymentBankSlipPaidValue
        ? { paidValue: Number(this.form.paymentBankSlipPaidValue) }
        : {}),
      ...(this.form.paymentBankSlipPaidDate
        ? { paidDate: this.form.paymentBankSlipPaidDate }
        : {}),
      ...(this.form.observation ? { observation: this.form.observation } : {})
    });

    if (!error) {
      this.$notify({ type: "success", text: "Alterações salvas!" });
      this.$emit("input");
      this.$emit("close");
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }

    this.isLoading = false;
  }

  formatReadableDate(date: string): string {
    return dayjs(date).format("DD/MM/YYYY");
  }

  get shouldShowPaidValueAndDate(): boolean {
    return this.STATUS_NAMES_THAT_SHOULD_SHOW_PAID_VALUE_AND_DATE.includes(
      this.newStatus.name
    );
  }

  get formTitle(): string {
    return (
      "Atualizar status do boleto (Lote " +
      this.paymentBankSlip.payment.paymentBatch.batchId +
      ")"
    );
  }
}
