export default interface BatchStatus {
  id: number;

  name: string;
  description: string;

  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

const openStatus: BatchStatus = {
  id: 1,
  name: "ABERTO",
  description: "Aberto"
};

const editingStatus: BatchStatus = {
  id: 2,
  name: "EM_TRATAMENTO",
  description: "Em tratamento"
};

const closedStatus: BatchStatus = {
  id: 3,
  name: "FECHADO",
  description: "Fechado"
};

export const BatchStatusEnum = {
  OPEN: openStatus,
  EDITING: editingStatus,
  CLOSED: closedStatus
};
