export const SegmentsEnum = {
  Industria: { id: 1, name: "Industria", text: "Indústria" },
  Comercio: { id: 2, name: "Comercio", text: "Comércio" },
  Servicos: { id: 3, name: "Servicos", text: "Serviços" },
  Publico: { id: 4, name: "Publico", text: "Público" }
} as const;

export const Segments = Object.values(SegmentsEnum);

export const SegmentNames = Segments.map((s) => s.name);

export const SegmentsAvailableForSelfRegister = [SegmentsEnum.Industria, SegmentsEnum.Comercio, SegmentsEnum.Servicos];