
import { Vue, Component, Watch } from "vue-property-decorator";
import Page from "@/components/core/dashboard/Page.vue";
import Batch from "@/types/batch";
import ListBatchesParams from "@/services/loan-service/types/list-batches-params";
import { DataOptions, DataTableHeader } from "vuetify";
import LoanService from "@/services/loan-service";
import BatchesManageModal from "@/components/batches/BatchesManageModal.vue";
import BatchRegisterCompanyUpdateModal from "@/components/batches/BatchRegisterCompanyUpdateModal.vue";
import BatchRegistersUpdateData, {
  BatchRegisterUpdateData
} from "@/services/loan-service/types/batch-registers-update-data";
import { ExtendedBatchRegister } from "@/types/batch-register";
import PaymentBankSlipManageModal from "@/components/batches/PaymentBankSlipManageModal.vue";
import DatePicker from "@/components/DatePicker.vue";
import debounce from "debounce";
import Modal from "@/components/Modal.vue";
import dayjs from "@/plugins/day-js";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import Company from "@/types/company";
import CompanyService from "@/services/company-service";
import { format as formatCNPJ } from "@/utils/cnpj";
import { isDue as bankSlipIsDue } from "@/utils/bankslip";
import BatchesList from "@/services/loan-service/types/list-batches-response";
import PaymentStatus, {
  PaymentStatusEnum,
  getPaymentStatusReadableNameById
} from "@/types/payment-status";
import PaymentBankSlip from "@/types/payment-bank-slip";
import { BatchStatusEnum } from "@/types/batch-status";
import { getBankSlipCurrentStatus } from "@/utils/bankslip";
import ReopenBatchModal from "@/components/batches/ReopenBatchModal.vue";
import PaymentService from "@/services/payment-service";
import PaymentOthers from "../types/payment-others";
import { exportToSpreadsheet } from "../utils/exportToSpreadsheet";

type BatchAndPayment = Batch & {
  paymentBankSlip: PaymentBankSlip | PaymentOthers | undefined;
};

interface BatchesAndPaymentsList extends BatchesList {
  items: Array<BatchAndPayment>;
  total: number;
}

@Component({
  components: {
    BatchesManageModal,
    PaymentBankSlipManageModal,
    ReopenBatchModal,
    Modal,
    Page,
    DatePicker,
    BatchRegisterCompanyUpdateModal
  }
})
export default class Batches extends Vue {
  formatCNPJ = formatCNPJ;
  batchRegister: BatchRegistersUpdateData | null = null;
  bankSlipIsDue = bankSlipIsDue;
  getPaymentStatusReadableNameById = getPaymentStatusReadableNameById;
  BatchStatusEnum = BatchStatusEnum;
  PaymentStatusEnum = PaymentStatusEnum;
  service: LoanService;
  companyService: CompanyService;
  paymentService: PaymentService;
  companies: Company[] = [];
  batchRegistersUpdateData: BatchRegistersUpdateData;
  batchesAndPayments: BatchesAndPaymentsList = {
    items: [],
    total: 0
  };
  filters: ListBatchesParams = {
    page: 1,
    limit: 10,
    sort: "generationDate:DESC"
  };
  options = {} as DataOptions;
  headers: Array<DataTableHeader> = [
    { text: "Lote", value: "id" },
    { text: "Data da geração", value: "generationDate" },
    { text: "Empresa", value: "companyName" },
    { text: "Data vencimento", value: "dueDate" },
    { text: "Data limite", value: "limitDate" },
    { text: "Status lote", value: "statusId", sortable: false },
    {
      text: "Situação boleto/pagamento",
      value: "paymentBankSlip.status",
      sortable: false
    },
    ...(this.hasPermissions(["ATUALIZAR_COBRANCA"])
      ? [
          {
            text: "Status boleto/cobrança",
            value: "paymentStatusId",
            sortable: false
          }
        ]
      : []),
    {
      text: "Ações",
      value: "actions",
      cellClass: "text-end",
      class: "text-end",
      sortable: false
    }
  ];
  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL;

  editBatch: Batch | null = null;
  showBatch: Batch | null = null;
  paymentBatch: Batch | null = null;
  reopenBatch: Batch | null = null;
  showBatches = false;
  editPaymentBankSlip: PaymentBankSlip | null = null;
  editPaymentBankSlipNewStatus: PaymentStatus | null = null;
  loadingBatchesData = false;
  loadingPaymentsData = false;
  showUpdateRegisterOwnerModal: boolean;
  searchCompany = "";
  batchStatusList = Object.values(BatchStatusEnum);
  paymentStatusList = [
    { id: -1, readableName: "Em branco" },
    ...Object.values(PaymentStatusEnum)
  ];
  DEFAULT_PAYMENT_STATUS: Pick<PaymentStatus, "name" | "id" | "readableName"> =
    PaymentStatusEnum.OPEN;
  getBankSlipCurrentStatus = getBankSlipCurrentStatus;

  constructor() {
    super();
    this.service = LoanService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.paymentService = PaymentService.getInstance();
    this.batchRegister = null;
    this.showUpdateRegisterOwnerModal = false;
  }

  @Watch("options")
  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.page = tableOptions.page;
    this.filters.limit = tableOptions.itemsPerPage;
    this.filters.sort = this.formatSort(
      tableOptions.sortBy,
      tableOptions.sortDesc
    );

    this.fetchBatchesAndPayments();

    return tableOptions;
  }

  applyFilter() {
    this.onOptionsChange({ ...this.options, page: 1 });
  }

  fetchCompanies = debounce(async (search: string) => {
    const [error, companies] = await this.companyService.listCompanies({
      page: 1,
      limit: 10,
      search,
      sort: "name:ASC"
    });
    this.companies.splice(0);
    companies?.items.forEach((company: Company) => {
      this.companies.push(company);
    });
  }, 700);

  async fetchBatchesAndPayments(): Promise<BatchesAndPaymentsList> {
    this.loadingBatchesData = true;
    this.loadingPaymentsData = true;

    let batchesAndPayments: BatchesAndPaymentsList = { items: [], total: 0 };
    const shouldFetchAllRecords = this.filters.limit === -1;
    let page = this.filters.page!;
    const limit = shouldFetchAllRecords ? 1000 : this.filters.limit!; // When fetching all records, take chunks of 1000 records
    let shouldFetchMoreBatches = true;

    while (shouldFetchMoreBatches) {
      let [batchesError, batches] = await this.service.listBatches({
        ...this.filters,
        page,
        limit
      });

      if (!batchesError) {
        batchesAndPayments.total = batches!.total;

        batchesAndPayments.items.push(...(batches!.items as BatchAndPayment[]));

        const [paymentsError, payments] =
          await this.paymentService.listPaymentsByBatchIds(
            batches!.items.map((b) => b.id)
          );

        if (!paymentsError) {
          batchesAndPayments.items = batchesAndPayments.items.map((b) => {
            if (b.paymentBankSlip) return b;

            const foundPayment = [
              ...(payments?.paymentOthers || []),
              ...(payments?.bankSlips || [])
            ].find((payment) => payment.payment.paymentBatch.batchId === b.id);

            return {
              ...b,
              paymentBankSlip: foundPayment
            };
          });
        } else {
          this.$notify({
            type: "error",
            text: getErrorMessageFromApiError(paymentsError)
          });
        }

        // If we're not supposed to fetch all records OR if the number of batches is less than the limit, it means there are no more batches to fetch
        if (!shouldFetchAllRecords || batches!.items.length < limit) {
          shouldFetchMoreBatches = false;
        } else {
          page++;
        }
      } else {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(batchesError)
        });
        shouldFetchMoreBatches = false;
      }
    }

    this.batchesAndPayments = batchesAndPayments;
    this.loadingPaymentsData = false;
    this.loadingBatchesData = false;
    return this.batchesAndPayments;
  }

  async updateBatchRegisterOwner(item: {
    companyId: number;
    destinationBatchId: number;
    batchRegisterId: number;
  }): Promise<void> {
    const [updateBatchRegisterError] =
      await this.service.updateBatchRegisterBatchId(item.batchRegisterId, {
        batchId: item.destinationBatchId,
        companyId: item.companyId
      });

    if (updateBatchRegisterError) {
      return this.$notify({
        title: "Erro ao transferir contrato",
        type: "error",
        text: getErrorMessageFromApiError(updateBatchRegisterError)
      });
    }

    this.$notify({
      title: "Lote atualizado",
      type: "success",
      text: "Contrato atualizado com sucesso."
    });

    this.showUpdateRegisterOwnerModal = false;
    this.closeModal();
  }

  getBackgroundColorForBankSlip(paymentBankSlip: PaymentBankSlip): string {
    if (paymentBankSlip.payment?.status.name === PaymentStatusEnum.OPEN.name) {
      return bankSlipIsDue(paymentBankSlip.dueDate) ? "#ffcdd2" : "#c8e6c9";
    } else {
      return "";
    }
  }

  formatDate(date: string): string {
    return dayjs(date).format("DD/MM/YYYY");
  }

  closeModal(): void {
    this.paymentBatch = null;
    this.reopenBatch = null;
    this.showBatch = null;
    this.editBatch = null;
    this.editPaymentBankSlip = null;
    this.editPaymentBankSlipNewStatus = null;
  }

  closeLoanUpdateCompanyModal(): void {
    this.showUpdateRegisterOwnerModal = false;
  }

  updateBatchRegisterCompany(item: BatchRegistersUpdateData) {
    this.showUpdateRegisterOwnerModal = true;
    this.batchRegister = { ...item };
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ?? "generationDate";
    const order = sortDesc[0] ? "ASC" : "DESC";

    return `${attr}:${order}`;
  }

  exportBatchesAndPaymentsXLSX(): void {
    const sheet = [
      [
        "Numero Lote",
        "Data Geração",
        "Empresa",
        "CNPJ",
        "Data Limite",
        "Data Vencimento Boleto",
        "Status Lote",
        "Situação Boleto",
        "Status Boleto",
        "Valor Total",
        "Valor de Repasse "
      ]
    ];

    this.batchesAndPayments.items.forEach((item) => {
      const row = [
        item.id.toString(),
        this.formatDate(item.generationDate),
        item.companyName,
        this.formatCNPJ(item.companyCnpj),
        this.formatDate(item.limitDate),
        this.formatDate(item.paymentBankSlip?.dueDate ?? ""),
        item.status.description ?? "",
        getBankSlipCurrentStatus({
          bankSlipDueDate: item.paymentBankSlip?.dueDate,
          paymentStatus: item.paymentBankSlip?.payment?.status
        }),
        item.paymentBankSlip?.payment?.status.readableName ?? "",
        item.installmentsTotal.toString() ?? "",
        item.total.toString() ?? ""
      ];

      sheet.push(row);
    });

    exportToSpreadsheet({
      data: sheet,
      fileName: "Consignações_e_Repasses_" + dayjs().format("YYYYMMDDHHmmss")
    });
  }

  get isAdminGooroo() {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }
}
