var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid, reset }){return [_c('v-dialog',{attrs:{"value":_vm.show,"max-width":"768px"},on:{"click:outside":function($event){_vm.$emit('close');
      reset();}}},[(_vm.item)?_c('v-card',{staticClass:"py-6"},[_c('v-card-title',{staticClass:"justify-center primary--text pb-8"},[_vm._v("Transferência CNPJ ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.companies.items,"item-text":(item) => `${item.name} - ${_vm.formatCNPJ(item.cnpj)}`,"item-value":"id","label":"Selecione a empresa Destino","disabled":_vm.isLoading},on:{"change":_vm.validateSelection},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.batches.items,"item-text":(item) =>
                  `Lote ${item.id} - ${_vm.formatReferenceDate(
                    item.generationDate
                  )}`,"item-value":"id","label":"Mês referência Destino","disabled":!_vm.selectedCompany || _vm.batches.total == 0},model:{value:(_vm.selectedBatch),callback:function ($$v) {_vm.selectedBatch=$$v},expression:"selectedBatch"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('v-text-field',{attrs:{"outlined":"","value":_vm.portabilityDate,"label":"Data transferência","disabled":""}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"px-6",attrs:{"color":"primary","large":"","disabled":invalid || _vm.isLoading || !_vm.selectedCompany || !_vm.selectedBatch,"loading":_vm.isLoading},on:{"click":function($event){_vm.isLoading = true;
            _vm.handleBatchRegisterUpdateOwner();
            reset();}}},[_vm._v("Confirmar")]),_c('v-spacer')],1)],1):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }